<template>
  <div>
    <section class="kategoriler-urunler">
      <div class="sliderCar">
        <div>
          <h2>{{ lang.kategoriler }}</h2>
        </div>
        <div class="sliderC">
          <slide v-for="(slide, index) in categories.slice().reverse()" :key="index">
            <div v-show="index+1 == last" style="width: 40vw">
              <img :alt="slide.heading" :src="slide.img">
            </div>
          </slide>
          <div class="btnContent">
            <span @click="catSelect(-1)">
<svg class="feather feather-chevron-left" fill="none" height="24" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
     stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polyline
    points="15 18 9 12 15 6"></polyline></svg></span>
            <span @click="catSelect(1)">
<svg class="feather feather-chevron-right" fill="none" height="24" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
     stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polyline
    points="9 18 15 12 9 6"></polyline></svg>
            </span>
          </div>
        </div>
      </div>
      <h2 class="sliderCarD"
          data-aos="fade-right"
          data-aos-anchor-placement="top-center"
          data-aos-delay="0"
          data-aos-duration="500"
          data-aos-easing="ease-in"
          data-aos-mirror="true"
          data-aos-offset="0"
          data-aos-once="false">{{ lang.kategoriler }}</h2>
      <div class="kategoriler sliderCarD"
           data-aos="fade-up"
           data-aos-anchor-placement="top-center"
           data-aos-delay="0"
           data-aos-duration="500"
           data-aos-easing="ease-in"
           data-aos-mirror="true"
           data-aos-offset="0"
           data-aos-once="false">
        <div v-for="item in categories.slice().reverse()" class="kategori">
          <div class="kategori-foto">
            <scroll-parallax :down="true" direction="y">
              <img :alt="item.heading" :src="item.img">
            </scroll-parallax>
          </div>
          <router-link :class="item.id == btn ? 'whiteGreen' : 'greenWhite'" :to="'/urunler/'+item.seo" class="btn"
                       @click="searchListFiltered(item.id)"><span>{{ item.heading }}</span></router-link>
        </div>
      </div>
      <img class="bg" height="auto" src="../../../public/assets/img/kategori-bg.png" width="100%"/>
    </section>
    <section class="urunler-liste-4">
      <router-link v-for="item in products.slice().reverse()" :to="'/urun/'+item.seo" class="urun"
                   data-aos="fade-up"
                   data-aos-anchor-placement="top-center"
                   data-aos-delay="0"
                   data-aos-duration="500"
                   data-aos-easing="ease-in"
                   data-aos-mirror="true"
                   data-aos-offset="0"
                   data-aos-once="false">
        <img class="bg" src="../../../public/assets/img/urun-alt.jpg" width="100vw"/>
        <div class="foto">
          <img v-lazy="item.img[0]" alt="">
        </div>
        <h3> {{ item.name }} </h3>
      </router-link>
      <img class="bgcizgi" src="../../../public/assets/img/sayfa-cizgi-yesil-2.png"/>
    </section>
  </div>
</template>
<script>
import iso from "../../axios";
export default {
  name: 'App',
  data() {
    return {
      categories: [],
      products: [],
      pve: [],
      lang: [],
      last: 1,
      btn: 0,
    }
  },
  methods: {
    load: function (){
       iso.get('categories')
          .then(response => {
            this.categories = response.data
          })
          .catch(error => {
            console.log(error);
          })
          iso.get('langfix')
        .then(response => {
          this.lang = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
      iso.get('products')
          .then(response => {
            this.pve = response.data
            this.products = response.data
          })
          .catch(error => {
            console.log(error);
          })
      if (this.$route.params.cat){
        iso.get('categories/' + this.$route.params.cat)
            .then(response => {
              this.searchListFiltered(response.data.id)
              console.log(response.data.id)
            })
            .catch(error => {
              console.log(error);
            })
      }

    },
    searchListFiltered: function (e) {
      this.last = parseInt(e)
      this.btn = parseInt(e)
      this.products = this.pve.filter((x) => {
        return (x.cat == e)
      })
    },
    catSelect: function (e) {
      const val = parseInt(this.last) + parseInt(e)
      console.log(val)

      if (val <= this.categories.length && val >= 1) {
        this.searchListFiltered(val)
      } else if (val < 1) {
        this.searchListFiltered(this.categories.length)
      } else {
        this.searchListFiltered(1)
      }
    }
  },
  created() {
    this.load()
  },
  beforeRouteLeave (to, from, next) {
    next();
    this.load()
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    this.load()
    window.scrollTo(0, 0);
  }
}
</script>
<style scoped>
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}

.urunler-liste-4 {
  position: relative;
  width: 100vw;
  margin-top: 40px;
  clear: both;
  padding-bottom: 10vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  row-gap: 20px;
}

.urunler-liste-4 .bgcizgi {
  position: absolute;
  width: 100%;
  height: auto;
  top: 153px;
  left: -235px;
  z-index: -5;
}

.urunler-liste-4 .urun {
  flex: 0 0 20%;
  background-color: #ffffff;
  color: #111111;
  text-decoration: none;
  float: left;
  position: relative;
  margin: 1%;
  border-radius: 1.5vw;
  transition: all .3s ease;
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
  margin-bottom: 50px;
}

.urunler-liste-4 .urun:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgb(255, 0, 0) 0px 30px 60px -30px;
  transform: translateY(-20px);;
}

.urunler-liste-4 .urun .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 1.5vw;
}

.urunler-liste-4 .urun .foto {
  position: relative;
  width: 100%;
  top: -1.5vw;
  height: 10vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-end;
  min-height: 200px;
}

.urunler-liste-4 .urun .foto img {
  width: auto;
  height: 200px;
}

.urunler-liste-4 .urun h3 {
  position: relative;
  color: #690c10;
  font-family: 'Raleway', sans-serif;
  font-size: calc(16px + .5vw);
  font-weight: bolder;
  width: 100%;
  text-align: center;
  z-index: 9;
}

.kategoriler-urunler {
  width: 100%;
  position: relative;
  clear: both;
  padding-bottom: 1vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.kategoriler-urunler .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -3;
  min-height: 75px;
}

.kategoriler-urunler h2 {
  font-family: elyazi;
  font-size: calc(30px + 1vw);
  margin: 8px 0px 8px 0px;
  color: white;
  margin-top: 6vw;
  float: left;
  text-align: right;
}

.kategoriler-urunler .kategoriler {
  width: 80%;
  float: right;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-end;
  align-items: stretch;
  justify-content: space-between;
}

.kategoriler-urunler .kategoriler .kategori {
  margin: 1%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.kategoriler-urunler .kategoriler .kategori .kategori-foto {
  width: 85%;
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
}

.kategoriler-urunler .kategoriler .kategori .kategori-foto img {
  width: 100%;
  height: auto;
}

.kategoriler-urunler .kategoriler .kategori a {
  position: relative;
  top: -30px;
  text-decoration: none;
  margin: 0;
}

.sliderCar {
  display: none;
}

.sliderCarD {
  display: block;
}

.sliderC {
  max-width: 50vw;
  position: relative;
  top: -20px;
}

.sliderC .btnContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
}

.sliderC .btnContent span {
  cursor: pointer;

  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  text-align: center;
  line-height: 23px;
}
@media (max-width: 900px) {
  .kategoriler-urunler .kategoriler .kategori a {
    top: -15px;
    padding: 5px 20px;
  }
}
@media (max-width: 600px) {
  .sliderCarD {
    display: none !important;
  }

  .kategoriler-urunler .kategoriler {
    width: 100%;
  }

  .sliderCar {
    display: flex;
    width: 100vw;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-start;

  }
}

</style>
